import React, { useState, useEffect } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Breadcrumb, Radio } from 'react-bootstrap';
import './components.css';
import ConsumptionRequest from './conreq';
import datasource from '../datasource';

const ConsumptionDevice = (props) => {
	const [scope, setScope] = useState(localStorage.getItem(props.workspace.id + "-scope") || "openid");
	const [acr, setAcr] = useState(localStorage.getItem(props.workspace.id + "-acr") || "mod-pr");
	const [hint, setHint] = useState(localStorage.getItem(props.workspace.id + "-hint") || "");
	const [hintType, setHintType] = useState(localStorage.getItem(props.workspace.id + "-hinttype") || "login_hint");
	const [message, setMessage] = useState(localStorage.getItem(props.workspace.id + "-message") || "");
	const [code, setCode] = useState(localStorage.getItem(props.workspace.id + "-code") || "");
	const [requests, setRequests] = useState({});

	function scopeChange(e) {
		setScope(e.target.value);
	}

	function acrChange(e) {
		setAcr(e.target.value);
	}

	function hintChange(e) {
		setHint(e.target.value);
	}

	function hintTypeChange(e) {
		setHintType(e.target.value);
	}

	function messageChange(e) {
		setMessage(e.target.value);
	}

	function codeChange(e) {
		setCode(e.target.value);
	}

	function send() {
		localStorage.setItem(props.workspace.id + "-scope", scope);
		localStorage.setItem(props.workspace.id + "-acr", acr);
		localStorage.setItem(props.workspace.id + "-hint", hint);
		localStorage.setItem(props.workspace.id + "-hinttype", hintType);
		localStorage.setItem(props.workspace.id + "-message", message);
		localStorage.setItem(props.workspace.id + "-code", code);
		datasource.createAuthReq(props.workspace.id, { scope: scope, acr: acr, hint: hint, hint_type: hintType, message: message, code: code }, handleUpdate);
	}

	function handleUpdate(ar) {
		requests[ar.id] = ar;
		setRequests(requests);
	}

	function handleClose(id) {
		datasource.unsubscribe(id);
		delete requests[id];
		setRequests(requests);
	}

	return (
		<>
			<Breadcrumb>
			<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
			<Breadcrumb.Item href={`/${props.workspace.namespace}/${props.workspace.project}`}>{props.workspace.namespace}/{props.workspace.project}</Breadcrumb.Item>
			<Breadcrumb.Item active>Consumption Device</Breadcrumb.Item>
			</Breadcrumb>
			<Panel>
			<Panel.Heading>Create Authentication Request</Panel.Heading>
			<Panel.Body>
			<FormGroup>
			<Col xs={3}><ControlLabel>Scopes:</ControlLabel></Col>
			<Col xs={9}><FormControl type="text" value={scope} onChange={scopeChange}/></Col>
			{
				props.workspace.cdConfig.mode != 'device' &&
				<>
					<Col xs={3}><ControlLabel>ACR Values:</ControlLabel></Col>
					<Col xs={9}><FormControl type="text" value={acr} onChange={acrChange}/></Col>
					<Col xs={12}><ControlLabel>Hint:</ControlLabel></Col>
					<Col xs={12}><FormControl componentClass="textarea" value={hint} onChange={hintChange}/></Col>
					<Col xs={12}>
						<Radio name="hinttype" value="login_hint" inline checked={hintType == 'login_hint'} onChange={hintTypeChange}>login_hint</Radio>
						<Radio name="hinttype" value="id_token_hint" inline checked={hintType == 'id_token_hint'} onChange={hintTypeChange}>id_token_hint</Radio>
						<Radio name="hinttype" value="login_hint_token" inline checked={hintType == 'login_hint_token'} onChange={hintTypeChange}>login_hint_token</Radio>
						<br/><br/>
					</Col>
					<Col xs={4}><ControlLabel>Binding Message:</ControlLabel></Col>
					<Col xs={8}><FormControl type="text" value={message} onChange={messageChange}/></Col>
					<Col xs={4}><ControlLabel>User Code:</ControlLabel></Col>
					<Col xs={8}><FormControl type="text" value={code} onChange={codeChange}/></Col>
				</>
			}
			<Col xs={12}><Button bsStyle="primary" onClick={send} disabled={props.workspace.cdConfig.auth.length < 12}>Send</Button></Col>
			</FormGroup>
			</Panel.Body>
			</Panel>
			<h3>Authentication Requests</h3>
			<Row>
			{
				Object.entries(requests).map(([id, req]) => {
					return <ConsumptionRequest key={id} req={req} onClose={() => handleClose(id)}/>
				})
			}
			</Row>
		</>
	);
}

export default ConsumptionDevice;
