import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grid, Navbar } from 'react-bootstrap';
import './App.css';
import datasource from './datasource';
import Home from './components/home';
import Workspace from './components/workspace';
import SimpleAD from './components/simplead';

const App = () => {
  const [status, setStatus] = useState('init');

  useEffect(() => {
    datasource.connect(process.env.REACT_APP_WEBSOCKET_URL, function(status) {
      setStatus(status);
    });
    return () => {
      datasource.disconnect();
    };
  }, []);

  return (
    <Router>
    <Switch>
      <Route path="/ad/:adId" component={SimpleAD}/>
      <Route><>
      <Navbar>
        <Navbar.Brand><a href="/">Authlete CIBA Simulator</a></Navbar.Brand>
      </Navbar>
      <Grid>
        <Route exact path="/" component={Home}/>
        <Route path="/:namespace/:project" component={Workspace}/>
      </Grid>
      </></Route>
    </Switch>
    </Router>
  );
}

export default App;
