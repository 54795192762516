import React, { useState, useEffect } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Well } from 'react-bootstrap';
import { sprintf } from 'sprintf-js';
import './components.css';
import datasource from '../datasource';

const AuthenticationRequest = (props) => {
	var s = datasource.secondsUntil(props.expire);
	if (s < 0)
		s = 0;
	const [countdown, setCountdown] = useState(s);

	useEffect(() => {
		var interval = setInterval(() => {
			var s = datasource.secondsUntil(props.expire);
			if (s < 0 || props.status != 'active') {
				s = 0;
				clearInterval(interval);
			}
			setCountdown(s);
		}, 1000);
		return () => { clearInterval(interval); };
	}, [props.reqId]);

	const allow = () => {
		datasource.action('allow', props.adId, props.reqId);
	};
	const deny = () => {
		datasource.action('deny', props.adId, props.reqId);
	};

	const style = {
		textAlign: 'center'
	};
	const allowStyle = props.status == 'active' || props.status == 'allow' ? 'success' : 'default';
	const denyStyle = props.status == 'active' || props.status == 'deny' ? 'danger' : 'default';
	var status = "";
	if (props.status == 'active')
		status = sprintf("%02d:%02d", countdown / 60, countdown % 60);
	else if (props.status == 'allow')
		status = "Allowed";
	else if (props.status == 'deny')
		status = "Denied";
	else if (props.status == 'timeout')
		status = "Timed out";
	else if (props.status == 'abort')
		status = "Aborted";
	var cbStatus = "";
	if (props.cbStatus == 'exec')
		cbStatus = "Callback: running";
	else if (props.cbStatus == 'done')
		cbStatus = "Callback: complete";
	else if (props.cbStatus == 'fail')
		cbStatus = "Callback: " + props.info;
	return (
		<Col xs={4}>
			<Well style={style}>
				<h4>{props.msg}</h4><br/>
				<p>{status}</p>
				<Button bsStyle={allowStyle} disabled={props.status != 'active' || countdown <= 0} onClick={allow}>Allow</Button>&nbsp;
				<Button bsStyle={denyStyle} disabled={props.status != 'active' || countdown <= 0} onClick={deny}>Deny</Button>
				{cbStatus}
			</Well>
		</Col>
	);
}

export default AuthenticationRequest;
