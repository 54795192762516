import React, { useState, useEffect, useRef } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Breadcrumb } from 'react-bootstrap';
import './components.css';
import AuthenticationRequest from './authreq';
import datasource from '../datasource';
import QRCode from 'qrcode';

const AuthenticationDevice = (props) => {
	const [authdev, setAuthDev] = useState({ id: null });
	const canvasEl = useRef(null);

	useEffect(() => {
		datasource.createAuthDev(props.workspace.id, props.userId).then((ad) => {
			if (ad !== null)
				setAuthDev(ad);
			else
				setAuthDev({ id: null, failed: true });
		}).catch((err) => {
			console.log("Failed to get authdev:", err);
		});
	}, [props.workspace.id, props.userId]);

	useEffect(() => {
		datasource.subscribe(authdev.id, (ad) => {
			setAuthDev(ad || { id: null, failed: true });
		});
		return () => { datasource.unsubscribe(authdev.id); };
	}, [authdev.id]);

	if (authdev.failed)
		return <p>Failed! :(</p>;
	if (!authdev.id)
		return <p>Loading...</p>;

	if (canvasEl && canvasEl.current)
		QRCode.toCanvas(canvasEl.current, "https://cibasim.authlete.com/ad/" + authdev.id);

	return (
		<>
			<Breadcrumb>
			<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
			<Breadcrumb.Item href={`/${props.workspace.namespace}/${props.workspace.project}`}>{props.workspace.namespace}/{props.workspace.project}</Breadcrumb.Item>
			<Breadcrumb.Item active>Authentication Device: {props.userId}</Breadcrumb.Item>
			</Breadcrumb>
			<a href={`/ad/${authdev.id}`}><canvas ref={canvasEl} style={{ position: 'fixed', top: '70px', right: '0px', transform: 'scale(0.5, 0.5)' }} title="Link to mobile friendly AD" /></a>
			<h3>Authentication Requests</h3>
			<Row>
			{
				Object.entries(authdev.requests).map(([id, req]) => {
					return <AuthenticationRequest key={id} adId={authdev.id} reqId={id} msg={req.msg} status={req.status} expire={req.expire} cbStatus={req.cb_status} info={req.info}/>
				})
			}
			</Row>
		</>
	);
}

export default AuthenticationDevice;
