import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';
import OpenCreate from './opencreate';

export default function Home() {
	return (
		<Row>
			<Col xs={3}/>
			<Col xs={6}>
				<p style={{border: '1px solid red', textAlign: 'center', padding: 5}}>
				This CIBA AD/CD Simulator is a debug tool for developing CIBA-compliant AS/OP.<br/>
				It is made available without any promise of privacy, availability, or reliability.<br/>
				</p>
				<OpenCreate/>
				<h3>About</h3>
				<p>The Authlete CIBA AD/CD Simulator, henceforth referred to as <i>cibasim</i>, implements
				an Authentication Device and a Consumption Device according to the CIBA specification
				(as far as hitting a moving target is realistic).</p>
				<p>Cibasim is meant as a tool for developers implementing a CIBA-compliant AS/OP. It is not
				a conformance test or a replacement for proper production systems.</p>
				<p>
				⚠️ All data entered into cibasim, including e.g. client secrets, should be treated as publicly
				accessible, as anyone can open any workspace if they know its name.<br/><br/>
				⚠️ Data stored in cibasim is purged on a last recently used basis. Your workspace can be deleted
				at any time. However, at current usage it should be kept for months.<br/><br/>
				⚠️ Upgrade/maintenance might occur at any time. Availability is not guaranteed.
				</p>
				<h3>Usage</h3>
				<p>First, create a workspace by filling in <i>namespace</i> and <i>project</i> above and clicking <i>Create</i>.
				A workspace is fundamentally a collection of configuration for the AD and CD simulators to use.
				AD and CD simulators can be used independently. If preferred, you can create separate workspaces for AD and CD.</p>
				<p></p>
			</Col>
			<Col xs={3}/>
		</Row>
	);
}
