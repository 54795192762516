import React, { useState, useEffect } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Breadcrumb, Radio } from 'react-bootstrap';
import './components.css';
import AuthenticationDevice from './authdev';
import ConsumptionDevice from './condev';
import datasource from '../datasource';

const Workspace = (props) => {
	const [workspace, setWorkspace] = useState({ id: null });
	const [userId, setUserId] = useState('');
	const [adCallback, setAdCallback] = useState('');
	const [cdAuth, setCdAuth] = useState('');
	const [cdToken, setCdToken] = useState('');
	const [cdMode, setCdMode] = useState('');
	const [cdClient, setCdClient] = useState('');
	const [cdMethod, setCdMethod] = useState('');
	const [cdSecret, setCdSecret] = useState('');

	useEffect(() => {
		console.log("Getting workspace...", props.match.params.namespace, props.match.params.project);
		datasource.getWorkspace(props.match.params.namespace, props.match.params.project).then((ws) => {
			console.log("Workspace: ", ws);
			if (ws !== null) {
				setWorkspace(ws);
				localStorage.setItem("namespace", ws.namespace);
				localStorage.setItem("project", ws.project);
			}
			else
				setWorkspace({ id: null, notFound: true });
		}).catch((err) => {
			console.log("Failed to get workspace:", err);
		});
	}, [props.match.params.namespace, props.match.params.project]);

	useEffect(() => {
		console.log("WS subscribing", workspace.id);
		datasource.subscribe(workspace.id, (ws) => {
			setWorkspace(ws || { id: null, notFound: true });
			setAdCallback(ws ? ws.adConfig.callback : '');
			setCdAuth(ws ? ws.cdConfig.auth : '');
			setCdToken(ws ? ws.cdConfig.token : '');
			setCdMode(ws ? (ws.cdConfig.mode || 'push') : 'push')
			setCdClient(ws ? (ws.cdConfig.client || '') : '');
			setCdMethod(ws ? ws.cdConfig.method : '');
			setCdSecret(ws ? ws.cdConfig.secret : '');
			setUserId(ws ? localStorage.getItem(ws.id + "-userid") || '' : '');
		});
		return () => { datasource.unsubscribe(workspace.id); };
	}, [workspace.id]);

	function adCallbackChange(e) {
		setAdCallback(e.target.value.trim());
	}

	function adConfigSave() {
		datasource.update(workspace.id, 'adConfig', { callback: adCallback }).then((res) => {
			if (res) {
				workspace.adConfig.callback = adCallback;
				setWorkspace(workspace);
			}
		}).catch((err) => {
			console.log("AD config save error:", err);
		});
	}

	function userIdChange(e) {
		setUserId(e.target.value.trim());
	}

	function cdAuthChange(e) {
		setCdAuth(e.target.value.trim());
	}

	function cdTokenChange(e) {
		var v = e.target.value.trim();
		setCdToken(v);
		if (v.length < 12)
			setCdMode('push');
	}

	function cdModeChange(e) {
		setCdMode(e.target.value);
	}

	function cdClientChange(e) {
		var v = e.target.value.trim();
		setCdClient(v);
		if (v.length == 0)
			setCdMethod('');
	}

	function cdMethodChange(e) {
		setCdMethod(e.target.value);
	}

	function cdSecretChange(e) {
		setCdSecret(e.target.value.trim());
	}

	function cdConfigSave() {
		datasource.update(workspace.id, 'cdConfig', { auth: cdAuth, token: cdToken, mode: cdMode, client: cdClient, method: cdMethod, secret: cdSecret }).then((res) => {
			if (res) {
				workspace.cdConfig.auth = cdAuth;
				workspace.cdConfig.token = cdToken;
				workspace.cdConfig.mode = cdMode;
				workspace.cdConfig.client = cdClient;
				workspace.cdConfig.method = cdMethod;
				workspace.cdConfig.secret = cdSecret;
				setWorkspace(workspace);
			}
		}).catch((err) => {
			console.log("CD config save error:", err);
		});
	}

	function openAD() {
		localStorage.setItem(workspace.id + "-userid", userId);
		props.history.push(props.match.url + "/ad/" + userId);
	}

	function openCD() {
		props.history.push(props.match.url + "/cd");
	}

	if (workspace.notFound)
		return <p>Workspace not found. Go <Link to="/">home</Link>.</p>
	if (!workspace.id)
		return <p>Loading...</p>
	return (
		<Switch>
		<Route exact path="/:namespace/:project/ad/:userId" render={(props) => <AuthenticationDevice {...props} workspace={workspace} userId={props.match.params.userId}/>}/>
		<Route exact path="/:namespace/:project/cd" render={(props) => <ConsumptionDevice {...props} workspace={workspace}/>}/>
		<Route exact path="/:namespace/:project">
		<>
			<Breadcrumb>
			<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
			<Breadcrumb.Item active>{workspace.namespace}/{workspace.project}</Breadcrumb.Item>
			</Breadcrumb>
			<Panel>
			<Panel.Heading>Authentication Device</Panel.Heading>
			<Panel.Body>
				<FormGroup>
				<Col xs={2}><ControlLabel>User ID:</ControlLabel></Col>
				<Col xs={4}><FormControl type="text" value={userId} onChange={userIdChange}/></Col>
				<Col xs={2}><Button bsStyle="primary" onClick={openAD} disabled={userId.length == 0}>Launch AD simulator</Button></Col>
				<Col xs={4}/>
				</FormGroup>
				<Row><Col xs={12}><span className="explanation">Arbitrary user ID that your AS/OP can use to initiate simulated authentication requests (subject/MSISDN, etc)</span></Col></Row>
			</Panel.Body>
			</Panel>
			<Panel>
			<Panel.Heading>Consumption Device</Panel.Heading>
			<Panel.Body>
				<FormGroup>
				<Col xs={2}><Button bsStyle="primary" onClick={openCD} disabled={workspace.cdConfig.auth.length < 12}>Launch CD simulator</Button></Col>
				</FormGroup>
			</Panel.Body>
			</Panel>
			<Panel>
			<Panel.Heading>Configuration</Panel.Heading>
			<Panel.Body>
			<FormGroup>
			<Row><Col md={2}><ControlLabel>AD Callback URL:</ControlLabel></Col>
			<Col md={6}><FormControl type="text" value={adCallback} onChange={adCallbackChange}/></Col>
			<Col md={4}><span className="explanation">Optional; required for AD simulator async mode</span><br/></Col></Row>
			<Row><Col md={12}><Button bsStyle="danger" disabled={adCallback == workspace.adConfig.callback} onClick={adConfigSave}>Save</Button></Col></Row>
			</FormGroup>
			<FormGroup>
			<Row><Col md={2}><ControlLabel>BC Authentication Endpoint URL:</ControlLabel></Col>
			<Col md={6}><FormControl type="text" value={cdAuth} onChange={cdAuthChange}/></Col>
			<Col md={4}><span className="explanation">Required for CD simulator</span><br/></Col></Row>
			<Row><Col md={2}><ControlLabel>Token Endpoint URL:</ControlLabel></Col>
			<Col md={6}><FormControl type="text" value={cdToken} onChange={cdTokenChange}/></Col>
			<Col md={4}><span className="explanation">Required for poll and ping modes</span><br/></Col></Row>
			<Row><Col md={2}><ControlLabel>Token Delivery Mode:</ControlLabel></Col>
			<Col md={10}>
				<Radio name="cdmode" value="poll" inline checked={cdMode == 'poll'} disabled={cdToken.length < 12} onChange={cdModeChange}>Poll</Radio>
				<Radio name="cdmode" value="ping" inline checked={cdMode == 'ping'} disabled={cdToken.length < 12} onChange={cdModeChange}>Ping</Radio>
				<Radio name="cdmode" value="push" inline checked={cdMode == 'push'} onChange={cdModeChange}>Push</Radio>
				<Radio name="cdmode" value="device" inline checked={cdMode == 'device'} onChange={cdModeChange}>Device Flow</Radio>
				<br/>
			</Col></Row>
			<Row><Col md={2}><ControlLabel>Client ID:</ControlLabel></Col>
			<Col md={4}><FormControl type="text" value={cdClient} onChange={cdClientChange}/></Col>
			<Col md={6}><span className="explanation">Optional; will be included in CD authentication requests if provided</span><br/></Col></Row>
			<Row><Col md={2}><ControlLabel>Client Authentication:</ControlLabel></Col>
			<Col md={10}>
				<Radio name="cdmethod" value="" inline checked={cdMethod == ''} onChange={cdMethodChange}>None</Radio>
				<Radio name="cdmethod" value="basic" inline checked={cdMethod == 'basic'} disabled={cdClient.length == 0} onChange={cdMethodChange}>Basic</Radio>
				<Radio name="cdmethod" value="post" inline checked={cdMethod == 'post'} disabled={cdClient.length == 0} onChange={cdMethodChange}>Post</Radio>
				<br/>
			</Col></Row>
			<Row><Col md={2}><ControlLabel>Client Secret:</ControlLabel></Col>
			<Col md={6}><FormControl type="text" value={cdSecret} disabled={!['basic', 'post'].includes(cdMethod)} onChange={cdSecretChange}/></Col>
			<Col md={4}><span className="explanation">Required for basic or post client authentication</span><br/></Col></Row>
			<Row><Col md={12}><Button bsStyle="danger" disabled={cdAuth == workspace.cdConfig.auth && cdToken == workspace.cdConfig.token && cdMode == workspace.cdConfig.mode && cdClient == workspace.cdConfig.client && cdMethod == workspace.cdConfig.method && cdSecret == workspace.cdConfig.secret} onClick={cdConfigSave}>Save</Button></Col></Row>
			</FormGroup>
			</Panel.Body>
			</Panel>
		</>
		</Route>
		</Switch>
	);
}

export default withRouter(Workspace);
