import React, { useState, useEffect } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Well } from 'react-bootstrap';
import { sprintf } from 'sprintf-js';
import './components.css';
import datasource from '../datasource';

const ConsumptionRequest = (props) => {
	var req = props.req;
	var status = "Unknown";
	switch (req.status) {
	case 'bc_sent':
		status = "BC auth request sent";
		break;
	case 'bc_fail':
		status = "BC auth request failed";
		break;
	case 'poll':
		status = "Polling";
		break;
	case 'ping':
		status = "Received ping callback";
		break;
	case 'wait':
		status = "Waiting for callback";
		break;
	case 'tk_fail':
		status = "Token request failed";
		break;
	case 'ps_fail':
		status = "Pushed result indicates fail";
		break;
	case 'done':
		status = "Completed";
		break;
	case 'deny':
		status = "Access was denied";
		break;
	case 'timeout':
		status = "Timed out";
		break;
	}
	const closeStyle = { position: 'absolute', color: 'red', cursor: 'pointer', left: 0, top: 0, display: 'inline-block', fontSize: '1.5em' };
	return (
		<Col xs={6}>
			<Well>
			<span style={closeStyle} onClick={props.onClose}>&times;</span>
			<Row><Col xs={4}>Status:</Col><Col xs={8}>{status}</Col></Row>
			{req.dc && <Row><Col xs={4}>device_code:</Col><Col xs={8}>{req.dc}</Col></Row>}
			{req.uc && <Row><Col xs={4}>user_code:</Col><Col xs={8}>{req.uc}</Col></Row>}
			{req.vu && <Row><Col xs={4}>verification_uri:</Col><Col xs={8}><a href={req.vu} class="small" target="_blank">{req.vu}</a></Col></Row>}
			{req.vuc && <Row><Col xs={4}>verification_uri_complete:</Col><Col xs={8}><a href={req.vuc} class="small" target="_blank">{req.vuc}</a></Col></Row>}
			{req.auth_req_id && <Row><Col xs={4}>auth_req_id:</Col><Col xs={8}>{req.auth_req_id}</Col></Row>}
			{req.info && <Row><Col xs={4}>Info:</Col><Col xs={8}>{req.info}</Col></Row>}
			{req.error && <Row><Col xs={4}>Error:</Col><Col xs={8}>{req.error}</Col></Row>}
			{req.content && <Row><Col md={4}>Result:</Col><Col md={8}><pre style={{fontSize: '0.7em', whiteSpace: 'pre-wrap'}}>{req.content ? JSON.stringify(req.content) : ''}</pre></Col></Row>}
			</Well>
		</Col>
	);
}

export default ConsumptionRequest;
