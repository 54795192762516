import React, { useState, useEffect } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, HelpBlock, Button, Breadcrumb } from 'react-bootstrap';
import './components.css';
import AuthenticationRequest from './authreq';
import datasource from '../datasource';

const SimpleAD = (props) => {
	const [authdev, setAuthDev] = useState({ id: null });

	useEffect(() => {
		datasource.subscribe(props.match.params.adId, (ad) => {
			console.log(ad);
			if (ad !== null)
				setAuthDev(ad);
			else
				setAuthDev({ id: null, failed: true });
		});
		return () => { datasource.unsubscribe(authdev.id); };
	}, [props.match.params.adId]);

	if (authdev.failed)
		return <p>Failed! :(</p>;
	if (!authdev.id)
		return <p>Loading...</p>;

	var reqs = [];
	if (authdev.requests)
		Object.entries(authdev.requests).map(([id, req]) => { reqs.push(req) });
	var req = reqs.length == 0 ? null : reqs[reqs.length - 1];
	console.log(reqs);
	console.log(authdev);

	const center = {
		position: 'fixed',
		top: '45%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '80%',
		maxHeight: '80%'
	};

	if (!req)
		return (
			<img src="/authlete-logo.jpg" style={center}/>
		);

	console.log(req.msg);
	const re = /^Client App \((.*)\).*\[Requested scopes\]: (.*)\[Binding message\]: (.*)$/;
	const m = re.exec(req.msg);
	if (m) {
		var app = m[1];
		var scopes = m[2];
		var msg = m[3];
	}
	else
		var msg = req.msg;

	return (
		<div className="simple">
			<div className="header">Authentication Request</div>
			<div className="appname">{app}</div>
			<div className="scopes">{scopes}</div>
			<div className="message">{msg}</div>
		</div>
	);
}

export default SimpleAD;
