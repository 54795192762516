import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, ControlLabel, FormControl, HelpBlock, Button } from 'react-bootstrap';
import './components.css';
import datasource from '../datasource';
import lodash from 'lodash';

var query = lodash.debounce((namespace, project, cb) => {
	datasource.getWorkspace(namespace, project).then((ws) => {
		console.log(ws);
		cb(ws !== null);
	}).catch((err) => console.log(err));;
}, 100);

const OpenCreate = (props) => {
	const [namespace, setNamespace] = useState(localStorage.getItem('namespace') || '');
	const [project, setProject] = useState(localStorage.getItem('project') || '');
	const [status, setStatus] = useState(1); // too short, unknown, exists, create, processing, ascii only

	const allowed = new RegExp("^[^/]{5,}$");

	useEffect(() => {
		if (namespace.length < 5 || project.length < 5)
			setStatus(0);
		else if (!allowed.test(namespace) || !allowed.test(project))
			setStatus(5);
		else {
			if (status === 0)
				setStatus(1);
			query(namespace, project, (exists) => setStatus(exists ? 2 : 3));
		}
	}, [namespace, project]);

	function namespaceChange(e) {
		setNamespace(e.target.value.trim());
	}

	function projectChange(e) {
		setProject(e.target.value.trim());
	}

	function handleOpen() {
		localStorage.setItem('namespace', namespace);
		localStorage.setItem('project', project);
		setStatus(4);
		props.history.push("/" + namespace + "/" + project);
	}

	function handleCreate() {
		localStorage.setItem('namespace', namespace);
		localStorage.setItem('project', project);
		setStatus(4);
		datasource.createWorkspace(namespace, project).then((ws) => {
			console.log(ws);
			props.history.push("/" + namespace + "/" + project);
		});
	}

	return (
		<FormGroup>
	    <ControlLabel>Namespace:</ControlLabel>
	    <FormControl type="text" value={namespace} onChange={namespaceChange}/>
	    <span className="explanation">Company/organization/nickname</span><br/><br/>
	    <ControlLabel>Project:</ControlLabel>
	    <FormControl type="text" value={project} onChange={projectChange}/>
	    <span className="explanation">Arbitrary name for your workspace</span><br/><br/>
	    {(() => {
	    	if (status === 0)
	    		return <HelpBlock>Namespace and project must be at least 5 characters</HelpBlock>;
	    	else if (status === 2)
	    		return <Button bsStyle="primary" onClick={handleOpen}>Open</Button>;
	    	else if (status === 3)
			    return <Button bsStyle="success" onClick={handleCreate}>Create</Button>;
			else if (status == 5)
				return <HelpBlock>Namespace and project names must be URL friendly</HelpBlock>;
		})()}
		</FormGroup>
	);
}

export default withRouter(OpenCreate);
