const random = require('random-base64-string');

class Datasource {
	constructor() {
		console.log("Datasource constructed");
		this.socket = null;
		this.interval = null;
		this.queries = {};
		this.subscriptions = {};
		this.connected = false;
		this.requestQueue = [];
		this.serverTimeOffset = 0;
		this.serverLatency = [0];
		var ping = function() {
			if (this.socket && this.connected)
				this.socket.send(JSON.stringify({ action: 'ping', time: Date.now() }));
		}.bind(this);
		setInterval(ping, 10000);
	}

	connect(url, cb) {
		this.socket = new WebSocket(url);
		if (this.interval)
			clearInterval(this.interval);
		this.interval = null;

		var reconnect = function() {
			console.log("WS reconnecting");
			this.connect(url, cb);
		}.bind(this);
		var queueReconnect = function() {
			if (this.interval == null)
				this.interval = setInterval(reconnect, 5000);
		}.bind(this);

		this.socket.onopen = function() {
			this.connected = true;
			this.sendQueuedRequests();
			console.log("WS connected");
			cb('connected');
		}.bind(this);

		this.socket.onclose = function() {
			this.connected = false;
			console.log("WS disconnected");
			cb('disconnected');
			queueReconnect();
		}.bind(this);

		this.socket.onerror = function(evt) {
			console.error("WS error:", evt)
			cb('error');
		};

		this.socket.onmessage = function(evt) {
			console.debug("WS message: ", evt.data);
			var data = JSON.parse(evt.data);
			if (data.pong) {
				this.serverLatency.push((Date.now() - data.pong) / 2);
				if (this.serverLatency.length > 10)
					this.serverLatency.shift();
			}
			if (data._time) {
				this.serverTimeOffset = Date.now() - data._time - this.serverLatency.reduce((t, n) => t + n, 0) / this.serverLatency.length;
				delete data._time;
			}
			var cb = null
			if (data._requestId) {
				cb = this.queries[data._requestId];
				delete data._requestId;
			}
			else if (data._subscribeId) {
				cb = this.subscriptions[data._subscribeId];
				delete data._subscribeId;
			}
			if (!cb)
				return;
			if (data._isNull)
				data = null;
			else if (data._isTrue)
				data = true;
			else if (data._isFalse)
				data = false;
			if (data && data._error && cb[1])
				cb[1](data._error);
			else
				cb[0](data);
		}.bind(this);
	}

	disconnect() {
		if (this.socket) {
			this.socket.close();
			this.socket = null;
		}
	}

	getWorkspace(namespace, project) {
		return this.request({ action: 'get', what: 'workspace', namespace: namespace, project: project });
	}

	createWorkspace(namespace, project) {
		return this.request({ action: 'create', what: 'workspace', namespace: namespace, project: project });
	}

	createAuthDev(workspaceId, userId) {
		return this.request({ action: 'create', what: 'authdev', workspace: workspaceId, user: userId });
	}

	update(id, what, data) {
		return this.request({ action: 'update', what: what, id: id, content: data });
	}

	createAuthReq(workspaceId, params, cb) {
		this.request({ action: 'create', what: 'authreq', workspace: workspaceId, params: params }).then((ar) => {
			this.subscriptions[ar.id] = [cb];
			cb(ar);
		}).catch((err) => {
			cb(null);
		});
	}

	subscribe(id, cb) {
		if (id === null)
			return;
		this.request({ action: 'subscribe', id: id }).then((res) => {
			if (res) {
				console.log("Subscribed: ", id);
				this.subscriptions[id] = [cb];
			}
			else
				console.log("Subscription failed: ", id);
		}).catch((err) => {
			console.log("Subscription failed: ", err);
		});
	}

	unsubscribe(id) {
		if (id === null)
			return;
		delete this.subscriptions[id];
		this.request({ action: 'unsubscribe', id: id }).then((res) => {
			console.log("Unsubscribed: ", id);
		}).catch((err) => {
			console.log("Unsubscribe failed: ", err);
		});
	}

	action(what, adId, reqId) {
		this.request({ action: 'action', what: what, adId: adId, reqId: reqId }).then((res) => {
			var cb = this.subscriptions[adId];
			if (res && 'id' in res && res.id && res.id == adId && cb)
				cb[0](res);
		}).catch((err) => {
		});
	}

	request(data) {
		var id = random(32);
		data._requestId = id;
		var queries = this.queries;
		this.send(data);
		return new Promise(function(resolve, reject) {
			var timeout = setTimeout(function() {
				if (queries[id]) {
					delete queries[id];
					reject('timeout');
				}
			}, 10000);
			queries[id] = [function(res) {
				delete queries[id];
				clearTimeout(timeout);
				resolve(res);
			}, function(err) {
				delete queries[id];
				clearTimeout(timeout);
				reject(err);
			}];
		});
	}

	send(data) {
		if (this.socket && this.connected)
			this.socket.send(JSON.stringify(data));
		else {
			this.requestQueue.push(data);
		}
	}

	sendQueuedRequests() {
		while (this.requestQueue.length > 0) {
			var data = this.requestQueue.shift();
			if (this.queries[data._requestId])
				this.send(data);
			else
				console.log("Query timed out");
		}
		Object.entries(this.subscriptions).map(([id, cb]) => {
			this.socket.send(JSON.stringify({ action: 'subscribe', id: id }));
		});
	}

	secondsUntil(t) {
		return Math.round((t - Date.now() + this.serverTimeOffset) / 1000);
	}
}

export default new Datasource();
